/*globals process*/
import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import { withPrismicUnpublishedPreview, componentResolverFromMap }
  from 'gatsby-plugin-prismic-previews';
import { SliceZone } from '../components';
import { linkResolver, transformFieldName } from '../utils/prismic-config';
import { useMergePrismicPreviewData } from '../utils/use-merge-prismic-preview-data';
import '../scss/404.scss';

import ArticleTemplate from '../templates/article';
import LoginPageTemplate from '../templates/login-page';
import PageTemplate from '../templates/page';
import ResourcesTemplate from '../templates/resources';
import SearchPageTemplate from '../templates/search-page';
import TrackingPageTemplate from '../templates/tracking-page';

function NotFoundPage () {
  const _data = useStaticQuery(graphql`
    query the404PageQuery {
      prismic404Page {
        _previewable

        data {
          title { text }
          metaDescription

          body {
            __typename
            ... on Prismic404PageDataBodyHero {
              ...the404PageHeroFields
            }
            ... on Prismic404PageDataBodyRelatedArticles {
              ...the404PageRelatedArticlesFields
            }
            ... on Prismic404PageDataBodyCustomCode {
              ...the404PageCustomCodeFields
            }
          }
        }

      }
    }
  `);

  const data = useMergePrismicPreviewData(_data);

  const doc = data.prismic404Page.data;

  return (
    <>
      <Helmet>
        <meta name="og:title" content={doc.title.text} />
        <meta name="description" content={doc.metaDescription} />
        <meta name="og:description" content={doc.metaDescription} />
        <title>{doc.title.text}</title>
      </Helmet>

      <div className="fwrd-404">
        <SliceZone sliceZone={doc.body} url={'/404'} />
      </div>
    </>
  );
}

export default withPrismicUnpublishedPreview(NotFoundPage, [
  {
    repositoryName: process.env.PRISMIC_REPOSITORY,
    linkResolver, transformFieldName,
    componentResolver: componentResolverFromMap({
      articles: ArticleTemplate,
      login_page: LoginPageTemplate,
      page: PageTemplate,
      resources: ResourcesTemplate,
      search_page: SearchPageTemplate,
      tracking_page: TrackingPageTemplate
    })
  }
]);
