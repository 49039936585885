/* global process */
import React from "react";
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { SliceZone } from "../components";
import { linkResolver, transformFieldName } from "../utils/prismic-config";
// import "../scss/search.scss";

function SearchPageTemplate ({ data, pageContext }) {
  const doc = data.prismicSearchPage.data;

  return (
    <>
      <Helmet>
        <title>{doc.title.text}</title>
        <meta name="og:title" content={doc.title.text} />
        <meta name="description" content={doc.metaDescription} />
        <meta name="og:description" content={doc.metaDescription} />
        <meta name="og:image" content={doc.image?.fixed?.src} />
      </Helmet>
      <SliceZone sliceZone={doc.body} url={pageContext.url}/>
    </>
  );
}

export const query = graphql `
query SearchPageQuery($id: String) {
  prismicSearchPage(id: { eq: $id }) {
    _previewable

    data {
      title { text }
      metaDescription
      image {
        fixed { src }
      }

      body {
        __typename
        ... on PrismicSearchPageDataBodyRelatedArticles {
          ...searchPageRelatedArticlesFields
        }
        ... on PrismicSearchPageDataBodyCustomCode {
          ...searchPageCustomCodeFields
        }
      }

    }
  }
}
`;

export default withPrismicPreview(SearchPageTemplate, [
  {
    repositoryName: process.env.PRISMIC_REPOSITORY,
    linkResolver, transformFieldName
  }
]);
