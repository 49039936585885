/* global process */
import React, { useState, useEffect } from "react";
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { useQueryParam, ArrayParam, withDefault } from 'use-query-params';
import {
  TrackingHero,
  TrackingHeader,
  TrackingWidget,
  SliceZone
} from "../components";
import { linkResolver, transformFieldName } from "../utils/prismic-config";
import "../scss/article.scss";
import "../scss/tracking.scss";

function TrackingPageTemplate ({ data, pageContext }) {
  const [ numbers, setNumbers ] = useQueryParam('numbers', withDefault(ArrayParam, []));
  const doc = data.prismicTrackingPage.data;

  const onChangeNumbers = (items) => {
    setNumbers(items);
  };

  const getHeaderContent = () => {
    if (numbers.length) {
      return doc.listingHeaderContent.html;
    }

    return doc.homeHeaderContent.html;
  };

  const getWidgetLabel = () => {
    if (numbers.length) {
      return doc.listingInputFieldLabel;
    }

    return doc.homeInputFieldLabel;
  };

  const getWidgetContent = () => {
    if (numbers.length) {
      return doc.listingContent.html;
    }

    return null;
  };

  // Fix for rehydration issue - check if client
  const [ isClient, setClient ] = useState(false);
  useEffect(() => {
    setClient(true);
  }, []);

  if (!isClient) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{doc.title.text}</title>
        <meta name="og:title" content={doc.title.text} />
        <meta name="description" content={doc.metaDescription} />
        <meta name="og:description" content={doc.metaDescription} />
        <meta name="og:image" content={doc.image?.fixed?.src} />
      </Helmet>
      <section className="fwrd-tracking-section">
        {
          !numbers.length &&
            <TrackingHero image={doc.homeTopImage.gatsbyImageData} />
        }
        <div className={`container tracking-result-header`}>
          <TrackingHeader headerContent={getHeaderContent()} />
        </div>
        <TrackingWidget
          label={getWidgetLabel()}
          listingContent={getWidgetContent()}
          statuses={doc.statuses}
          changeNumbers={onChangeNumbers}
          numbers={numbers}
        />
      </section>
      {
        !numbers.length &&
          <SliceZone sliceZone={doc.body} url={pageContext.url}/>
      }
    </>
  );
}

export const query = graphql `
query TrackingPageQuery($id: String) {
  prismicTrackingPage(id: { eq: $id }) {
    _previewable

    data {
      title { text }
      metaDescription
      image {
        fixed { src }
      }

      homeHeaderContent { html }
      homeInputFieldLabel
      homeTopImage { gatsbyImageData }


      listingHeaderContent { html }
      listingInputFieldLabel
      listingContent { html }

      statuses {
        statusCode
        progressState
        statusColor
        generalizedDescription
        nextStatusText
      }

      body {
        __typename
        ... on PrismicTrackingPageDataBodyRelatedArticles {
          ...trackingPageRelatedArticlesFields
        }
        ... on PrismicTrackingPageDataBodyCustomCode {
          ...trackingPageCustomCodeFields
        }
      }

    }
  }
}
`;

export default withPrismicPreview(TrackingPageTemplate, [
  {
    repositoryName: process.env.PRISMIC_REPOSITORY,
    linkResolver, transformFieldName
  }
]);
