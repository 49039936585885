/* eslint-disable react/jsx-max-depth */
/* global process */
import React, { useMemo } from "react";
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { NewsBody, ResourcesBody } from '../components';
import { linkResolver, transformFieldName } from '../utils/prismic-config';
import { Hero } from '../components/slice-zone/slices/hero';
import '../scss/resources.scss';

function ResourcesTemplate ({ data }) {
  const doc = data.prismicResources.data;
  let body = null;

  if (doc.mode === 'Resources') {
    body = (
      <ResourcesBody
        data={ doc }
        allResourceArticles={ data.allResourceArticles }
        featuredResourceArticles={ data.featuredResourceArticles.nodes }
        mostRecentResourceArticles={ data.mostRecentResourceArticles.nodes }
      />
    );
  } else if (doc.mode === 'News') {
    body = (
      <NewsBody
        data={ doc }
        featuredNewsArticles={ data.featuredNewsArticles.nodes }
        pressReleaseNewsArticles={ data.pressReleaseNewsArticles.nodes }
        blogNewsArticles={ data.blogNewsArticles.nodes }
      />
    );
  } else {
    console.warn(`Invalid mode for Resources: ${doc.mode}`);
  }

  const heroPrimary = useMemo(() => {
    return {
      heroContent: doc.heroContent,
      image: doc.heroImage,
      imageFocus: doc.heroImageFocus,
      colorMode: doc.heroColorMode,
      showSocialLinks: doc.heroShowSocialLinks
    };
  }, [ doc ]);

  return (
    <>
      <Helmet>
        <title>{doc.title.text}</title>
        <meta name="og:title" content={doc.title.text} />
        <meta name="description" content={doc.metaDescription} />
        <meta name="og:description" content={doc.metaDescription} />
        <meta name="og:image" content={doc.image?.fixed?.src} />
      </Helmet>
      <Hero primary={heroPrimary} items={[]} />
      { body }
    </>
  );
}

export const query = graphql `
query ResourcesQuery($id: String!, $isNews: Boolean!) {
  # Resources page
  allPrismicResources {
    pageInfo {
      hasNextPage
      hasPreviousPage
      itemCount
      pageCount
      perPage
      totalCount
      currentPage
    }
    edges {
      node {
        id
      }
    }
  }

  prismicResources(id: { eq: $id }) {
    _previewable

    data {
      mode
      title { text }
      metaDescription
      image {
        fixed { src }
      }
      # Fields for hero slice below
      heroImageFocus
      heroColorMode
      heroContent {
        html
      }
      heroImage {
        gatsbyImageData
        alt
      }
      heroShowSocialLinks
      # Field signup form section
      signupFormActionUrl {
        url
      }
      signupFormContent {
        html
      }
      signupFormCtaText
      signupFormImage {
        gatsbyImageData
        alt
      }
      signupFormType
    }
  }

  ###
  # Resources page articles -- featured, most recent, and all
  featuredResourceArticles: allPrismicArticles(
    filter: {
      data: {isNews: {eq: false}}
      tags: {eq: "featured"}
    }
    sort: {fields: last_publication_date, order: DESC}
    limit: 8
  ) @skip(if: $isNews) {
    nodes {
      ...ResourcesCard
    }
  }

  mostRecentResourceArticles: allPrismicArticles(
    filter: {
      data: {isNews: {eq: false}}
    }
    sort: {fields: data___publishDate, order: DESC}
    limit: 8
  ) @skip(if: $isNews) {
    nodes {
      ...ResourcesCard
    }
  }

  allResourceArticles: allPrismicArticles(
    filter: {
      data: {isNews: {eq: false}}
    }
    sort: {fields: data___publishDate, order: DESC}
  ) @skip(if: $isNews) {
    nodes {
      ...ResourcesCard
    }
    keywords: distinct(field: data___keywords___keyword)
  }

  ###
  # News page articles -- featured, press releases, and blog
  featuredNewsArticles: allPrismicArticles(
    filter: {
      data: {isNews: {eq: true}}
      tags: {eq: "featured"}
    }
    sort: {fields: data___publishDate, order: DESC}
    limit: 8
  ) @include(if: $isNews) {
    nodes {
      ...ResourcesCard
    }
  }

  pressReleaseNewsArticles: allPrismicArticles(
    filter: {
      data: {category: {eq: "Press Releases"}}
    }
    sort: {fields: data___publishDate, order: DESC}
    limit: 4
  ) @include(if: $isNews) {
    nodes {
      ...ResourcesCard
    }
  }
  
  blogNewsArticles: allPrismicArticles(
    filter: {
      data: {category: {eq: "News"}}
    }
    sort: {fields: data___publishDate, order: DESC}
    limit: 4
  ) @include(if: $isNews) {
    nodes {
      ...ResourcesCard
    }
  }
}

fragment ResourcesCard on PrismicArticles {
  _previewable
  url
  tags
  data {
    category
    title { text }
    description { text }
    image { gatsbyImageData, alt }
    publishDate
    readTime
    keywords { keyword }
  }
}
`;

export default withPrismicPreview(ResourcesTemplate, [
  {
    repositoryName: process.env.PRISMIC_REPOSITORY,
    linkResolver, transformFieldName
  }
]);
