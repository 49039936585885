/* global process */
import React from "react";
import { Helmet } from 'react-helmet';
import { IconButton } from '@mui/material';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { ArticleHeader, ArticleHero, ClickableChipsList, FeaturedArticles, NewToForward, SliceZone }
  from "../components";
import { BookmarkIcon, LinkedInIcon, TwitterIcon } from '../components/icons';
import { linkResolver, transformFieldName } from '../utils/prismic-config';
import '../scss/article.scss';

function ArticleTemplate ({ data, pageContext }) {
  const doc = data.prismicArticles.data;
  const clickableChipsArr = doc.keywords;

  const featuredArticles = () => {
    if (doc.featuredArticlesOverride.length > 0) {
      return <FeaturedArticles articles={ doc.featuredArticlesOverride } />;
    }
  };

  const heroRendered = !doc.heroImage.gatsbyImageData ? null : (
    <ArticleHero image={ doc.heroImage.gatsbyImageData } />
  );

  return (
    <>
      <Helmet>
        <title>{doc.title.text}</title>
        <meta name="og:title" content={doc.title.text} />
        <meta name="description" content={doc.description?.text} />
        <meta name="og:description" content={doc.description?.text} />
        <meta name="og:image" content={doc.image?.fixed?.src} />
      </Helmet>
      {heroRendered}
      <div className="fwrd-layout-column-two">
        <div className="container --small column-layout-flex">
          <div className="layout-column column-main">
            <ArticleHeader
              date={ doc.publishDate }
              readtime={ doc.readTime }
              category={ doc.category }
              author={ doc.author }
              header={ doc.title.html }
              subhead={ doc.subheading.html }
              actionsBarContent={
                <>
                  <IconButton aria-label="linkedin" size="large">
                    <LinkedInIcon />
                  </IconButton>
                  <IconButton aria-label="twitter" size="large">
                    <TwitterIcon />
                  </IconButton>
                  <IconButton aria-label="bookmark" size="large">
                    <BookmarkIcon />
                  </IconButton>
                </>
              }
            />
            <SliceZone sliceZone={doc.body} url={pageContext.url} />
            <div className="related-keywords">
              <ClickableChipsList header="Related Keywords" initialState={clickableChipsArr}/>
            </div>
          </div>
          <div className="layout-column column-news">
            <NewToForward />
            { featuredArticles() }
          </div>
        </div>
      </div>
    </>
  );
}

export const query = graphql `
query ArticlesQuery($id: String) {
  prismicArticles(id: { eq: $id }) {
    _previewable

    data {
      title { html, text }
      subheading { html }
      category
      readTime
      publishDate
      author
      description { html, text }
      image {
        fixed { src }
      }
      heroImage { gatsbyImageData, alt }
      heroImageFocus
      keywords { keyword }
      featuredArticlesHeadingOverride { html }

      featuredArticlesOverride {
        article {
          document {
            ... on PrismicArticles {
              _previewable
              url
              data {
                category
                title { text }
                publishDate
              }
            }
          }
        }
      }

      relatedPagesHeading { html }

      relatedPages {
        page {
          document {
            ... on PrismicPage {
              _previewable
              url
              data {
                title { text }
                image { gatsbyImageData }
              }
            }
          }
        }
      }

      body {
        __typename
        ... on PrismicArticlesDataBodyArticleContent {
          ...articleArticlesContentFields
        }
        ... on PrismicArticlesDataBodyNavigationGrid {
          ...articlesNavigationGridFields
        }
        ... on PrismicArticlesDataBodyServiceNavigationCards {
          ...articlesServiceNavigationCardsFields
        }
        ... on PrismicArticlesDataBodyCustomCode {
          ...articleCustomCodeFields
        }
      }

    }
  }
}
`;

export default withPrismicPreview(ArticleTemplate, [
  {
    repositoryName: process.env.PRISMIC_REPOSITORY,
    linkResolver, transformFieldName
  }
]);
