/* global process */
import React from "react";
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { SliceZone } from '../components';
import { linkResolver, transformFieldName } from '../utils/prismic-config';

function PageTemplate ({ data, path, pageContext }) { //eslint-disable-line no-unused-vars
  const doc = data.prismicPage.data;

  return (
    <>
      <Helmet>
        <title>{doc.title.text}</title>
        <meta name="og:title" content={doc.title.text} />
        <meta name="description" content={doc.metaDescription?.text} />
        <meta name="og:description" content={doc.metaDescription?.text} />
        <meta name="og:image" content={doc.image?.fixed?.src} />
      </Helmet>
      <SliceZone sliceZone={doc.body} url={pageContext.url} />
    </>
  );
}

export const query = graphql `
query PageQuery($id: String) {
  prismicPage(id: { eq: $id }) {
    _previewable

    data {
      title { text }
      metaDescription
      image {
        fixed { src }
      }

      body {
        __typename
        ... on PrismicPageDataBodyHero {
          ...pageHeroFields
        }
        ... on PrismicPageDataBodySignupForm {
          ...pageSignupFormFields
        }
        ... on PrismicPageDataBodyHeader {
          ...pageHeaderFields
        }
        ... on PrismicPageDataBodyValuePropositions {
          ...pageValuePropositionsFields
        }
        ... on PrismicPageDataBodyLogoFarm {
          ...pageLogoFarmFields
        }
        ... on PrismicPageDataBodyLeadership {
          ...pageLeadershipFields
        }
        ... on PrismicPageDataBodyImageSlider {
          ...pageImageSliderFields
        }
        ... on PrismicPageDataBodyFactsAndFigures {
          ...pageFactsAndFiguresFields
        }
        ... on PrismicPageDataBodyContentAndFeaturesBlocks {
          ...pageContentAndFeaturesBlocksFields
        }
        ... on PrismicPageDataBodyColumnsContent {
          ...pageColumnsContentFields
        }
        ... on PrismicPageDataBodyJobsCategoriesCards {
          ...pageJobsCategoriesCardsFields
        }
        ... on PrismicPageDataBodyRelatedArticles {
          ...pageRelatedArticlesFields
        }
        ... on PrismicPageDataBodyJobListings {
          ...pageJobListingsFields
        }
        ... on PrismicPageDataBodyTestimonialsSlider {
          ...pageTestimonialsSliderFields
        }
        ... on PrismicPageDataBodyFaqs {
          ...pageFaqsFields
        }
        ... on PrismicPageDataBodyServiceNavigationCards {
          ...pageServiceNavigationCardsFields
        }
        ... on PrismicPageDataBodyEndToEnd {
          ...pageEndToEndFields
        }
        ... on PrismicPageDataBodyNavigationGrid {
          ...pageNavigationGridFields
        }
        ... on PrismicPageDataBodyServiceMapOverview {
          ...pageServiceMapOverviewFields
        }
        ... on PrismicPageDataBodyArticleContent {
          ...articlePageContentFields
        }
        ... on PrismicPageDataBodyCustomCode {
          ...pageCustomCodeFields
        }
      }
    }
  }
}
`;

export default withPrismicPreview(PageTemplate, [
  {
    repositoryName: process.env.PRISMIC_REPOSITORY,
    linkResolver, transformFieldName
  }
]);
